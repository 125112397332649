.t18_topnav {
  z-index: 5;
  box-shadow: 2px 4px 9px -4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 4px 9px -4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 9px -4px rgba(0, 0, 0, 0.25);
  position: relative;
}
.t18_sidenav {
  z-index: 10;
  box-shadow: 5px -1px 17px -4px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 5px -1px 17px -4px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 5px -1px 17px -4px rgba(0, 0, 0, 0.25);
  position: relative;
}
.t18_nav_route {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: large;
  border-bottom: 1px solid #ddd;
}
.t18_nav_route:hover {
  color: rgba(243, 106, 90, 1);
}
.t18_nav_route_active {
  background: rgb(229, 0, 25);
  background: linear-gradient(
    90deg,
    rgba(229, 0, 25, 1) 0%,
    rgba(238, 65, 65, 1) 51%,
    rgba(243, 106, 90, 1) 100%
  );
  color: #fff;
}
.t18_nav_route_active:hover {
  color: #fff;
}
.t18_upload {
  margin-top: 0px;
  border: 1px solid rgb(114, 167, 236);
  border-radius: 7px;
  width: auto;
  overflow: hidden;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  padding: 2px;
  bottom: 0px;
}
.t18_upload_input {
  background-color: red;
  color: #fff;
}
input[type="file"] {
  display: none;
  /* position: absolute;
  left: -99999rem; */
}
.t18_upload_label {
  /* border: 1px solid #ccc; */
  /* display: inline-block; */
  /* padding: 6px 12px; */
  cursor: pointer;
  margin: 0px;
  color: rgb(39, 97, 173);
  font-size: small;
}
.t18_download {
  color: green;
  border: 1px solid green;
  border-radius: 7px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
